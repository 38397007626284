import { Currency, CurrencyAmount, Percent, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { useMemo } from 'react'
import { ApproveInfo, ClassicTrade, QuoteMethod, TradeState } from 'state/routing/types'
import { Pair, Route, Trade } from '@uniswap/v2-sdk'
import { Token, NativeCurrency } from '@uniswap/sdk-core'
import { useV2Pair, PairState } from './useV2Pairs'
import useBlockNumber from 'lib/hooks/useBlockNumber'
import { useTokenAllowance } from './useTokenAllowance'
import { V2_ROUTER_ADDRESSES } from '@uniswap/sdk-core'
import { UNIVERSAL_ROUTER_ADDRESS } from '@uniswap/universal-router-sdk'

export function useOnChainTrade(
  tradeType: TradeType,
  amountSpecified?: CurrencyAmount<Currency>,
  otherCurrency?: Currency,
  account?: string,
  inputTax?: Percent,
  outputTax?: Percent
) {
  const [currencyIn, currencyOut]: [Currency | undefined, Currency | undefined] = useMemo(
    () =>
      tradeType === TradeType.EXACT_INPUT
        ? [amountSpecified?.currency, otherCurrency]
        : [otherCurrency, amountSpecified?.currency],
    [amountSpecified, otherCurrency, tradeType]
  )

  const pairAddress = useMemo(() => {
    return (
      currencyIn instanceof Token && currencyOut instanceof Token ?
        Pair.getAddress(currencyIn, currencyOut) :
        undefined
    )
  },
    [currencyIn, currencyOut]
  )

  const [pairState, pair] = useV2Pair(currencyIn, currencyOut)

  const getTrade = useMemo(() => {
    if (pairState === PairState.EXISTS && pair && currencyIn && currencyOut && amountSpecified) {
      const route = new Route([pair], currencyIn, currencyOut)
      const amount = amountSpecified.quotient.toString()
      const trade = new Trade(route, amountSpecified, tradeType)
      console.log("trade: ", trade)
      return trade
    }
    else return undefined
  }, [pairState, pair, currencyIn, currencyOut, amountSpecified])

  //mock Data for v3 compatibility
  const gasUseEstimate = undefined
  const gasUseEstimateUSD = undefined
  const blockNumber = useBlockNumber()?.toString()
  const requestId = undefined
  const quoteMethod = QuoteMethod.CLIENT_SIDE_FALLBACK
  // type SwapFeeInfo = { recipient: string; percent: Percent; amount: string /* raw amount of output token */ }


  const routerAddress = currencyIn ? UNIVERSAL_ROUTER_ADDRESS(currencyIn.chainId) : undefined
  // const routerAddress = currencyIn ? V2_ROUTER_ADDRESSES[currencyIn.chainId] : undefined
  const { tokenAllowance, isSyncing: isSyncingAllowance } =
    useTokenAllowance(currencyIn as Token, account, routerAddress)
  const approveInfo: ApproveInfo = amountSpecified && (tokenAllowance?.greaterThan(amountSpecified) || tokenAllowance?.equalTo(amountSpecified)) ?
    { needsApprove: false } :
    { needsApprove: true, approveGasEstimateUSD: 1 } //!!! what logic for approveGasEstimateUSD

  const v2Trade = getTrade
  const swapFeePercent = new Percent(3, 1000)
  const swapFee = {
    recipient: pairAddress ? pairAddress : "",
    percent: swapFeePercent,
    amount: v2Trade ? v2Trade.outputAmount.multiply(swapFeePercent).quotient.toString() : ""
  }

  const classicTrade = v2Trade && v2Trade.route && v2Trade.inputAmount && v2Trade.outputAmount ? new ClassicTrade({
    gasUseEstimate,
    gasUseEstimateUSD,
    blockNumber,
    requestId,
    quoteMethod,
    approveInfo,
    swapFee,
    v2Routes: [{
      routev2: v2Trade.route,
      inputAmount: v2Trade.inputAmount,
      outputAmount: v2Trade.outputAmount,
    }],
    v3Routes: [],
    tradeType
  }
  ) :
    undefined

  const tradeResult = {
    state: classicTrade ? TradeState.VALID : TradeState.NO_ROUTE_FOUND,
    trade: classicTrade,
    currentTrade: classicTrade,
    swapQuoteLatency: classicTrade ? 50 : undefined
  }

  return tradeResult
}

